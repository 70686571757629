// 关联应用(1-衣加加,2-数聚时尚,3-综合体数字云仓,4-订货系统,5-乔司地图,6-技工大赛)
export const relatedApps = {
  1: '衣加加',
  2: '数聚时尚',
  3: '综合体数字云仓',
  4: '订货系统',
  5: '乔司时尚地图',
  6: '技工大赛',
}
/*
  账户来源(0-未知,1-衣加加APP注册,2-订货系统前台注册,3-数聚时尚信息平台前台注册,4-技工大赛前台,
  5-乔司时尚地图小程序注册,6-综合体数字云仓用户,7-衣加加云（PC端）注册,
  50-得体导入,51-得体生产加工类订单导入时生成的生产商)
  10 - 服装产业云平台
*/
export const userSources = {
  0: '未知',
  1: '衣加加APP',
  2: '订货系统',
  3: '数聚时尚',
  4: '技工大赛',
  5: '乔司时尚地图',
  6: '综合体数字云仓',
  7: '衣加加云',
  10: '服装产业云平台',
  50: '得体',
  51: '得体生产加工类订单',
}